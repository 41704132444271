import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Axios from 'axios'
Vue.prototype.$axios = Axios;

const routes = [
    {
        path: '/',
        name: 'frontpage',
        component: () => import('./views/Frontpage.vue'), 
        meta: { category: 'Frontpage Page', publicPage: true }
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('./views/product/Index.vue'), 
        meta: { category: 'Product Page', publicPage: true }
    },
        {
            path: '/product/admin',
            name: 'product-admin',
            component: () => import('./views/product/Admin.vue'), 
            meta: { category: 'Admin Page', publicPage: true }
        },
        {
            path: '/product/handheld',
            name: 'product-handheld',
            component: () => import('./views/product/Handheld.vue'), 
            meta: { category: 'Handheld Page', publicPage: true }
        },
    // {
    //     path: '/solutions',
    //     name: 'solutions',
    //     component: () => import('./views/Solutions.vue'), 
    //     meta: { category: 'Solutions Page', publicPage: true }
    // },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./views/Documentation.vue'), 
        meta: { category: 'Documentation Frontpage', publicPage: true }
    },
        {
            path: '/documentation/category/:categoryId?', //'/:slug?',
            name: 'documentation-category',
            component: () => import('./views/Documentation.vue'), 
            meta: { category: 'Documentation Category Page', publicPage: true }
        },
        {
            path: '/documentation/article/:articleId',
            name: 'documentation-article',
            // component: () => import('./views/DocumentationArticle.vue'), 
            component: () => import('./views/Documentation.vue'), 
            meta: { category: 'Documentation Article Page', publicPage: true }
        },
    {
        path: '/company',
        name: 'company',
        component: () => import('./views/Company.vue'), 
        meta: { category: 'Company Page', publicPage: true }
    },
    // {
    //     path: '/pricing',
    //     name: 'pricing',
    //     component: () => import('./views/Pricing.vue'), 
    //     meta: { category: 'Pricing Page', publicPage: true }
    // },
    {
        path: '/api',
        name: 'api',
        component: () => import('./views/Api.vue'), 
        meta: { category: 'API page', publicPage: true }
    },
    {
        path: '/getStarted',
        name: 'getStarted',
        component: () => import('./views/GetStarted.vue'), 
        meta: { category: 'Get Started Page', publicPage: true }
    },
    {
        path: '/partners',
        name: 'partners',
        component: () => import('./views/Partners.vue'), 
        meta: { category: 'Partners Page', publicPage: true }
    },
    
    
]

const router = new Router({
    mode: 'history', // default commented out
    // base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior (to, from, savedPosition) {
        // console.log('scrollBehavior called');
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
        else {
            return {
                x: 0, 
                y: 0,
                behavior: 'smooth',
            }
        }

    }
});

// router.beforeEach(async (to, from, next) => {
//     next()
// })

router.afterEach( async (to, from) => {
    // page tracker
    try {
        analytics.page( to.name , { 
            path: to.path,
            category: to.meta && to.meta.category ? to.meta.category : null,
        });
    } 
    catch (e) {
        console.log('error in segment');
        console.log(e);
    }
})

// router.onReady( async (to, from) => {
// 
// })

export default router;
